import { useEffect } from 'react'
import useLocalStorage from 'use-local-storage'
import './App.css'
import Header from './components/Header/Header'
import Hero from './components/UI/Hero'
import Counter from './components/UI/Counter'
import Services from './components/UI/Services'
import About from './components/UI/About'
import Skills from './components/UI/Skills'
import Blog from './components/UI/Blog'
import Testimonials from './components/UI/Testimonials'
import Newsletter from './components/UI/Newsletter'
import Footer from './components/Footer/Footer'

function App() {
  const [theme, setTheme] = useLocalStorage('theme', 'dark')

  const toggleTheme = () => {
    theme === 'dark' ? setTheme('light-theme') : setTheme('dark')
  }

  useEffect(() => {
    document.body.className = theme
  }, [theme])

  return (
    <>
      <Header theme={theme} toggleTheme={toggleTheme} />
      <Hero theme={theme} />
      <Counter />
      <Services />
      <About />
      <Skills />
      <Blog />
      <Testimonials />
      <Newsletter />
      <Footer />
    </>
  )
}

export default App
