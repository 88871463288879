import '../../styles/testimonials.css'
import Slider from 'react-slick'
import ava01 from '../../images/avatar-svgrepo-com.svg'
import ava02 from '../../images/avatar2.svg'
import ava03 from '../../images/avatar 3.svg'

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  }
  return (
    <section id="testimonials">
      <div className="container">
        <div className="slider__content-top">
          <h6 className="subtitle">Testimonials</h6>
          <h2>
            Here are some of the testaments{' '}
            <span className="highlight">from some our satisfied clients</span>
          </h2>
        </div>
        <div className="slider__wrapper">
          <Slider {...settings}>
            <div className="slider__item">
              <p className="description">
                Lewinskie offer practical solutions for any business. They
                created a business solution for us at a very affordable price.
                The solution further went on to save us thousands in in
                recurrent monthly payments to get this service provided by
                online company abroad.
              </p>
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava01} alt="" />
                </div>
                <div className="text__section-testimonials">
                  <h5 className="customer__name">Moses Ndegwa</h5>
                  <p className="description">Owner, Beam Grill & Lounge</p>
                </div>
              </div>
            </div>

            <div className="slider__item">
              <p className="description">
                Very dedicated, speedy delivery, amaizing prices, what's not to
                like? These guys gave us a digital menu that you can scan, for
                literally the price that most people pay for in a few months for
                a subcription service. And the greatest part, is the affordable
                maintenance prices they charge.
              </p>
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava02} alt="" />
                </div>
                <div>
                  <h5 className="customer__name">Martaza Hussein</h5>
                  <p className="description">Owner, Nakuru Snackway</p>
                </div>
              </div>
            </div>

            <div className="slider__item">
              <p className="description">
                I would definitely refer them to anyone who wants a speedy
                service, that is top-notch, and pocket friendly. Seriously,
                their services are an absolute steal! Services that work for the
                African Market, and meets the businessperson with an African
                pocket.
              </p>
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava03} alt="" />
                </div>
                <div>
                  <h5 className="customer__name">Eric Kibambasi</h5>
                  <p className="description">Manager, Chilli's Tavern</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  )
}
export default Testimonials
