import '../../styles/blog.css'
import videoImg from '../../images/video.png'
import articleImg from '../../images/article.png'
import caseStudy from '../../images/case-study.png'

const blogData = [
  {
    img: videoImg,
    title: 'Video',
    desc: 'A short video explaining what we do...',
    link: '#',
  },
  {
    img: articleImg,
    title: 'Article',
    desc: 'Why tech is not only crucial but necessary...',
    link: '#',
  },
  {
    img: caseStudy,
    title: 'Case Study',
    desc: 'A case study of a retail Saas for the African Market...',
    link: '#',
  },
]

const Blog = () => {
  return (
    <section id="blog">
      <div className="container">
        <div className="blog__top-content">
          <h6 className="subtitle">Our Blog</h6>
          <h2>
            Peruse through some articles{' '}
            <span className="highlight"> in our dedciated blog section</span>
          </h2>
        </div>
        <div className="blog__wrapper">
          {blogData.map((item, index) => (
            <div className="blog__item" key={index}>
              <h3>{item.title}</h3>
              <div className="blog__img">
                <img src={item.img} alt="" />
              </div>
              <p className="description blog__desc">{item.desc}</p>
              <div className="link__blog">
                <a href={item.link} className="learn__more">
                  <i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Blog
