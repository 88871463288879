import '../../styles/counter.css'

const counterData = [
  {
    number: 10,
    text: 'Clients',
  },
  {
    number: 5,
    text: 'Running Projects',
  },
  {
    number: 50,
    text: 'Completed Projects',
  },
]

const Counter = () => {
  return (
    <section className="counter" id="projects">
      <div className="container">
        <div className="counter__wrapper">
          {counterData.map((item, index) => (
            <div className="counter__item">
              <h3 className="counter__number">{item.number}+</h3>
              <h4 className="counter__title">{item.text}</h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Counter
