import '../../styles/newsletter.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Newsletter = () => {
  const notify = () =>
    toast('Message has been Successfully sent', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })
  const form = useRef()

  const clearItems = () => {
    form.current.reset()
  }

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_nurlky5',
        'template_hlwl2ea',
        form.current,
        'le38ZIPOPBSA0R9Xl'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )
    notify()
    clearItems()
  }
  return (
    <section className="newsletter" id="contact">
      <div className="container">
        <div className="newsletter__wrapper">
          <div className="newsletter__content">
            <h6 className="subtitle">Let's work</h6>
            <h2>
              Get in touch and experience{' '}
              <span className="highlight">the countless benefits </span>of
              working with us!
            </h2>
          </div>
          <div className="newsletter__form">
            <form ref={form} onSubmit={sendEmail} className="form_details">
              <label htmlFor="user_name">Name </label>
              <input
                type="text"
                required
                name="user_name"
                id="name"
                placeholder="Enter your Name"
              />
              <label htmlFor="user_email">Email </label>
              <input
                type="email"
                name="user_email"
                required
                id="email"
                placeholder="Enter your Email Address"
              />
              <label htmlFor="message">Your Query </label>
              <textarea
                name="message"
                id="text-area"
                required
                cols="30"
                rows="10"
                placeholder="How can we help you?"
              />
              <button type="submit" className="secondary__btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  )
}
export default Newsletter
