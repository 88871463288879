import './footer.css'

const quickLinks01 = [
  {
    path: '#',
    display: 'Computer Vision',
  },
  {
    path: '#',
    display: 'Data Analytics',
  },
  {
    path: '#',
    display: 'Personalized AI',
  },
  {
    path: '#',
    display: 'CyberSecurity Solutions',
  },
]

const quickLinks02 = [
  {
    path: '#',
    display: 'Pricing',
  },
  {
    path: '#',
    display: 'Guides',
  },
  {
    path: '#',
    display: 'Feasibility Study',
  },
  {
    path: '#',
    display: 'Projects',
  },
]

const quickLinks03 = [
  {
    path: '#',
    display: 'Home',
  },
  {
    path: '#about',
    display: 'About Us',
  },
  {
    path: '#blog',
    display: 'Blog',
  },
  {
    path: '#skills',
    display: 'Skills',
  },
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

var currentDate = new Date()
var month = months[currentDate.getMonth()]
var day = currentDate.getDate()
var year = currentDate.getFullYear()
var fullDate = month + ' ' + day + ', ' + year

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__logo">
            <div className="logo">
              <h2>L</h2>
              <span className="spans">EWIN</span>
              <h2 className="two">S</h2>
              <span className="spans two">KIE</span>
            </div>
            <p className="description">Africa Needs Tech</p>
            <p className="small__text description">
              Creating Sustainable, Practical, and Affordable Digital Solutions
              for African Businesses.
            </p>
          </div>
          <div className="footer__quick-links">
            <h3 className="quick__links-title">Upcoming Services</h3>
            <ul className="quick__links">
              {quickLinks01.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__quick-links">
            <h3 className="quick__links-title">Quick Links</h3>
            <ul className="quick__links">
              {quickLinks02.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer__quick-links">
            <h3 className="quick__links-title">Quick Links</h3>
            <ul className="quick__links">
              {quickLinks03.map((item, index) => (
                <li className="quick__link-item" key={index}>
                  <a href={item.path}>{item.display}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="final_touch">
          <h3 className="normal">
            © Lewinskie Inc. {'  '}
            <span className="highlight">
              {'  '}
              {fullDate}
            </span>
          </h3>
        </div>
      </div>
    </footer>
  )
}
export default Footer
