import { useRef, useEffect } from 'react'
import './header.css'

const nav__links = [
  {
    path: '#home',
    display: 'Home',
  },
  {
    path: '#about',
    display: 'About',
  },
  {
    path: '#service',
    display: 'Services',
  },
  {
    path: '#projects',
    display: 'Projects',
  },
  {
    path: '#blog',
    display: 'Blog',
  },
  {
    path: '#contact',
    display: 'Contact',
  },
]

const Header = ({ theme, toggleTheme }) => {
  const headerRef = useRef(null)

  const menuRef = useRef(null)

  const toggleMenu = () => {
    menuRef.current.classList.toggle('menu__active')
  }

  const headerFunc = () => {
    if (window.scrollY > 80) {
      headerRef.current.classList.add('header__shrink')
    } else {
      headerRef.current.classList.remove('header__shrink')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', headerFunc)
    return () => window.removeEventListener('scroll', headerFunc)
  }, [])

  const handleClick = (e, path) => {
    e.preventDefault()

    const targetElement = document.querySelector(path)
    const location = targetElement.offsetTop

    window.scrollTo({
      left: 0,
      top: location - 80,
      behavior: 'smooth',
    })
  }
  return (
    <header className="header" ref={headerRef}>
      <div className="container">
        <div className="nav__wrapper">
          <div className="logo">
            <h2>L</h2>
            <span className="spans">EWIN</span>
            <h2 className="two">S</h2>
            <span className="spans two">KIE</span>
          </div>
          {/* ==============navigation============== */}
          <div className="navigation" ref={menuRef} onClick={toggleMenu}>
            <ul className="menu">
              {nav__links.map((item, index) => (
                <li className="menu__link" key={index}>
                  <a
                    href={item.path}
                    className="menu__item"
                    onClick={(e) => handleClick(e, item.path)}
                  >
                    {item.display}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* =======================light/dark-mode=================*/}
          <div className="light__mode">
            <span onClick={toggleTheme}>
              {theme === 'light-theme' ? (
                <span className="dark__theme">
                  <i class="ri-moon-clear-line"></i>Dark Mode
                </span>
              ) : (
                <span>
                  <i class="ri-sun-line"></i> Light Mode
                </span>
              )}
            </span>
          </div>
          <span className="mobile__menu" onClick={toggleMenu}>
            <i class="ri-menu-line"></i>
          </span>
        </div>
      </div>
    </header>
  )
}
export default Header
