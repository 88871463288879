import '../../styles/services.css'

const serviceData = [
  {
    icon: 'ri-apps-line',
    title: 'App Development',
    desc: 'Let us develop high quality native mobile applications, progressive web Applications (PWAs), and even Desktop Applications, that are tailored to meet your business needs.',
  },
  {
    icon: 'ri-pages-line',
    title: 'Website Design',
    desc: "It's the 21st century!! You need a digital presence. Let us help you take your first step to going digital by creating a customized, beautiful, and stunning website for you.",
  },
  {
    icon: 'ri-landscape-line',
    title: 'Graphic Design',
    desc: 'In the age of social media, what your business needs is designed images that stand out, are beautiful and captivating. Let us develop these stunning designs for you.',
  },
  {
    icon: 'ri-code-box-line',
    title: 'Digital Solutions',
    desc: 'Hire us to develop for you a tailor-made IT solution for your business, to manage your inventory, manage employee attendance, track your fleet of vehicles, or anything of the sort.',
  },
]

const Services = () => {
  return (
    <section id="service" className="services">
      <div className="container">
        <div className="services__top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Digitize your Business with</h2>
          <h2 className="highlight">Our Array of Services and Products</h2>
        </div>
        <div className="service__item-wrapper">
          {serviceData.map((item, index) => (
            <div className="services_item" key={index}>
              <span className="services__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Services
