import '../../styles/skills.css'
import python from '../../images/python-4.svg'
import javascript from '../../images/logo-javascript.svg'
import css from '../../images/css-3.svg'
import tailwind from '../../images/tailwindcss.svg'
import nodeJs from '../../images/nodejs-1.svg'
import django from '../../images/django.svg'
import react from '../../images/react-2.svg'
import reactNative from '../../images/react-native-1.svg'
import mongoDB from '../../images/mongodb-icon-1.svg'
import sass from '../../images/sass-1.svg'
import postgres from '../../images/postgresql.svg'
import sqlite from '../../images/sqlite.svg'
import html from '../../images/html-1.svg'
import electron from '../../images/electron-1.svg'
import c from '../../images/c-1.svg'

const skillsData = [
  {
    img: python,
    title: 'Python',
  },
  {
    img: javascript,
    title: 'Javascript',
  },
  {
    img: css,
    title: 'CSS 3',
  },
  {
    img: tailwind,
    title: 'Tailwind CSS',
  },
  {
    img: nodeJs,
    title: 'Node JS',
  },
  {
    img: django,
    title: 'Django',
  },
  {
    img: react,
    title: 'React',
  },
  {
    img: reactNative,
    title: 'React Native',
  },
  {
    img: mongoDB,
    title: 'MongoDB',
  },
  {
    img: sass,
    title: 'Sass',
  },
  {
    img: postgres,
    title: 'PostgresSQL',
  },
  {
    img: sqlite,
    title: 'SQLite',
  },
  {
    img: html,
    title: 'HTML 5',
  },
  {
    img: electron,
    title: 'Electron JS',
  },
  {
    img: c,
    title: 'C',
  },
]

const Skills = () => {
  return (
    <section id="skills">
      <div className="container">
        <div className="skills__top-content">
          <h6 className="subtitle">Our Skills</h6>
          <h2>
            Here are a few of the technologies{' '}
            <span className="highlight">
              and programming frameworks we use.
            </span>
          </h2>
        </div>
        <div className="skills__wrapper">
          {skillsData.map((item, index) => (
            <div className="skills__item" key={index}>
              <div className="skills__img">
                <img src={item.img} alt="" />
              </div>
              <div className="skills__title">
                <h3 className="highlight">{item.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Skills
