import '../../styles/about.css'
import aboutImg from '../../images/office_suit_new.jpg'

const chooseData = [
  {
    icon: 'ri-wifi-line',
    title: 'Advanced knowledge in both business and programming.',
    desc: 'With deep experience in both business and programming we can develop tailor-made solutions that can make SMEs more profitable.',
  },
  {
    icon: 'ri-service-line',
    title: 'Intense Customer Focus/Obsession',
    desc: "We will scorch the earth to make sure that our clients are happy. It's all about customer satisfaction and meeting client's needs.",
  },
  {
    icon: 'ri-customer-service-2-line',
    title: '24/7 Hour Support.',
    desc: 'You can reach us at any time if you need us to help you solve any problem that you are experiencing.',
  },
]

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="about__intro">
          <h6 className="subtitle">About Us</h6>
          <h2>We Specialize in Providing Tech Solutions that </h2>
          <h2 className="highlight">
            Make Business More Profitable and Effective.
          </h2>
          <p className="description about__content-desc">
            Give your business a fighting chance in the competitive digital
            world. Be at the top of your game, and your industry.
          </p>
        </div>
        <div className="about__wrapper">
          <div className="about__content">
            <div className="choose__item-wrapper">
              {chooseData.map((item, index) => (
                <div className="choose__us-item" key={index}>
                  <span className="choose__us-icon">
                    <i class={item.icon}></i>
                  </span>
                  <div className="choose__us-title">
                    <h4>{item.title}</h4>
                    <p className="description new">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="about__img">
            <img src={aboutImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
export default About
